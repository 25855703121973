import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Table,
  TableContainer,
  TableTd,
  TableTh,
  TableThead,
  TableTr,
  ChevronDownIcon,
  DropdownMenu,
  SpeedometerIcon,
  MobilePhoneIcon,
  Pagination,
} from '@skywatch/ui'
import { useCollection } from '@skywatch/core'
import { Wait } from '@drive/shared'
import odometersCollection from './store/odometers.collection'
import { GetOdometersGridQueryRequest, Odometer, odometerStatuses } from './store/types'
import { getStatusElement, UserMonitoringDashboardHeader } from './components'

interface TableRow {
  effectiveDate: string
  userId: number
  policyNumber: string
  userEmail: string
  odometerStatus: string
  odometerDueDate: string
  policyId: string
  id: string
  isPendingApproval: boolean
  actions?: string
}
interface TableColumn {
  key: keyof TableRow
  header: JSX.Element | string
  onClickHeader?: () => void
}

const ITEM_LIMIT = 10

export default () => {
  const [filtering, setFiltering] = useState<GetOdometersGridQueryRequest>({
    limit: ITEM_LIMIT,
    page: 1,
    sortBy: '-EffectiveDate',
    searchValue: '',
    odometerStatus: '',
  })
  const odometers = useCollection(odometersCollection)
  const navigate = useNavigate()

  const odometerStatusMenu = [
    [{ text: 'All', action: () => setFiltering(prev => ({ ...prev, odometerStatus: '' })) }].concat(
      odometerStatuses.map(odometerStatus => {
        return {
          text: odometerStatus,
          action: () =>
            setFiltering(prev => ({
              ...prev,
              odometerStatus: odometerStatus,
            })),
        }
      })
    ),
  ]

  const setSortByValue = (col: string) => {
    switch (col) {
      case 'EffectiveDate': {
        setFiltering(prev => ({
          ...prev,
          sortBy: prev.sortBy === 'EffectiveDate' ? '-EffectiveDate' : 'EffectiveDate',
        }))
        return
      }
      default: {
        setFiltering(prev => ({
          ...prev,
          sortBy: prev.sortBy === 'OdometerDue' ? '-OdometerDue' : 'OdometerDue',
        }))
      }
    }
  }

  const viewPolicyOnClick = async (odometer: Odometer) => {
    navigate(`/user/${odometer.userId}/policy`)
  }

  const columns: TableColumn[] = [
    {
      key: 'effectiveDate',
      header: (
        <div className="flex flex-row items-center">
          <div>Effective Date</div>
          <a href="#" className="ml-2 rounded-xl bg-gray-100 text-gray-400  hover:bg-gray-200">
            <ChevronDownIcon className={`${filtering.sortBy === 'EffectiveDate' ? 'rotate-180' : ''}`} stroke="black" />{' '}
          </a>
        </div>
      ),
      onClickHeader: () => setSortByValue('EffectiveDate'),
    },
    { key: 'policyNumber', header: 'Policy Numnber' },
    { key: 'userEmail', header: 'User Email' },
    {
      key: 'odometerStatus',
      header: (
        <DropdownMenu items={odometerStatusMenu}>
          <a href="#" className="group inline-flex items-center">
            <div className="flex flex-col">
              Odometer Status
              {filtering.odometerStatus && <span className="text-xs text-gray-400">({filtering.odometerStatus})</span>}
            </div>
            <span className="ml-2 flex-none rounded-xl bg-gray-100 text-gray-900 hover:bg-gray-200">
              <ChevronDownIcon className="h-5 w-5 stroke-gray-950" aria-hidden="true" />
            </span>
          </a>
        </DropdownMenu>
      ),

      // 'Odometer Status'
    },
    {
      key: 'odometerDueDate',
      header: (
        <div className="flex flex-row items-center">
          <div>Odometer Due</div>
          <a href="#" className="ml-2 rounded-xl bg-gray-100 text-gray-400  hover:bg-gray-200">
            <ChevronDownIcon className={`${filtering.sortBy === 'OdometerDue' ? 'rotate-180' : ''}`} stroke="black" />{' '}
          </a>
        </div>
      ),
      onClickHeader: () => setSortByValue('OdometerDue'),
    },
    { key: 'actions', header: 'Actions' },
  ]

  const actions = (odometer: Odometer) => [
    [
      {
        text: 'Manage odometer',
        icon: SpeedometerIcon,
        action: () => navigate(`/odometer-manager/${odometer.policyId}`),
      },
      { text: 'View Policy', icon: MobilePhoneIcon, action: () => viewPolicyOnClick(odometer) },
    ],
  ]

  const getData = async () => {
    odometers.reset()
    await odometers.fetchOdometersDataGrid(filtering)
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtering])

  const onPageChange = (page: number) => {
    setFiltering(prev => ({ ...prev, page: page }))
  }

  const onSearch = (value: string) => {
    setFiltering(prev => ({ ...prev, searchValue: value }))
  }

  return (
    <>
      <UserMonitoringDashboardHeader searchValue={filtering.searchValue} onSearchChange={onSearch} />
      <TableContainer>
        <Table>
          <TableThead>
            <tr>
              {columns.map(column => (
                <TableTh key={column.key} onClick={column.onClickHeader}>
                  {column.header}
                </TableTh>
              ))}
            </tr>
          </TableThead>
          <tbody>
            {odometers.items.map(row => (
              <TableTr key={row.id}>
                {columns.map(column => {
                  switch (column.key) {
                    case 'actions':
                      return (
                        <TableTd key={column.key}>
                          <DropdownMenu
                            items={actions(row)}
                            className="z-10 flex w-max content-center self-center stroke-black"
                          >
                            <a href="#" className="flex flex-row items-center self-center">
                              <div className="text-primary-500 flex flex-col">Actions</div>
                              <ChevronDownIcon className="stroke-primary-500 h-5 w-5" aria-hidden="true" />
                            </a>
                          </DropdownMenu>
                        </TableTd>
                      )
                    case 'odometerStatus':
                      return <TableTd key={row.id}>{getStatusElement(row.odometerStatus)}</TableTd>
                    default:
                      return <TableTd key={column.key}>{row[column.key]}</TableTd>
                  }
                })}
              </TableTr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <Pagination
        defaultPage={1}
        maxPages={Math.ceil(odometers.state.meta?.total / ITEM_LIMIT)}
        visableRange={5}
        onPrev={onPageChange}
        onNext={onPageChange}
        onClick={onPageChange}
      />
      <Wait visible={odometers.isBusy} />
    </>
  )
}
